const JWT_TOKEN = 'jwt-token'
export { JWT_TOKEN }

const COMPANY_ID = 'company_id'
export { COMPANY_ID }

const DEVICE_ID = 'device_id'
export { DEVICE_ID }

export const APP_EVENTS = {
  app_loading: 'app-loading',
  app_modal: 'app-modal',
  app_confirm_modal: 'app-confirm-modal'
}

export const DATE_FORMAT_DDMMMyyyy = 'DD MMM yyyy'
export const TIME_FORMAT_hhmmA = 'hh.mmA'
export const DATE_FORMAT_ISO = 'YYYY-MM-DDTHH:mm:ss'

export const DEFAULT_TOPIC_FIELDS = ['Technician Assigned', 'Attach Job Order', 'Add Invoice']

export const SWITCH_FUNCTION = {
  country_code: true,
  employee_role: true,
  service_charge: true,
  promote_code: true,
  pdf_ver2: true,
  config_price: true,
  onetime_notes: true,
  delete_review: true,
  tech_cus_required_only_contact: false,
  timeframe: true,
  timeframe_limit: false,
  cutoff_duration: true,
  remove_COD: true,
  hierachy_category: true,
  surcharge: true,
  residential_type: true,
  multi_address: true,
  contract_frequency: false,
  multi_onetime: false,
  show_feature_version: false,
  apply_login_2fa: false,
  remove_quote_from_flow: true,
  tech_not_collect: false,
  data_retention: false,
  marketing_module: true
}
